<template>
	<div class="Signin mx-auto my-16">
		<v-card class="d-flex align-start flex-column mx-auto" elevation="0">
			<v-card-title class="px-0 pt-0">
				<h1 class="heading" v-html="$t('loginForm.title')"></h1>
			</v-card-title>
			<div class="form">
				<validation-observer ref="observer">
					<form @submit.prevent="signin">
						<validation-provider v-slot="{ errors }" name="id">
							<v-text-field
								v-model="signinForm.id"
								:error-messages="errors"
								:label="$t('loginForm.id')"
							/>
						</validation-provider>
						<validation-provider v-slot="{ errors }" name="password">
							<v-text-field
								v-model="signinForm.password"
								type="password"
								:error-messages="errors"
								:label="$t('loginForm.password')"
								@keyup="checkCapsLock"
							>
								<template v-slot:append>
									<div
										v-if="isCapsLockOn"
										class="d-flex flex-column justify-center"
										style="position: relative; bottom: 4px"
									>
										<v-icon>mdi-arrow-up-bold-box-outline</v-icon>
										<strong style="font-size: 8px">CapsLock</strong>
									</div>
								</template>
							</v-text-field>
						</validation-provider>
						<v-btn
							class="button email-login full-width mb-3"
							type="submit"
							width="100%"
							large
							color="primary"
							:disabled="!isSubmitValid"
							:loading="signinForm.loading"
						>
							{{ $t('loginForm.submit') }}
						</v-btn>
						<kakao-signin-button :redirect-url="kakaoRedirectUrl" />
						<div class="d-flex justify-center">
							<span class="span-text" @click="routeToFindPassword">
								{{ $t('loginForm.findPassword') }}
							</span>
							<div class="vertical-line"></div>
							<span class="span-text" @click="routeToFindEmail">
								{{ $t('loginForm.findId') }}
							</span>
							<div class="vertical-line"></div>
							<span class="span-text" @click="routeToSignup">
								{{ $t('loginForm.signup') }}
							</span>
						</div>
					</form>
				</validation-observer>
			</div>
		</v-card>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { useRouter } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'
import { internationalPhoneFormat } from '@/filter'

import AuthService from '@/services/AuthService'

import KakaoSigninButton from '@/pages/components/KakaoSigninButton.vue'

const _signinForm = {
	id: '',
	password: '',
	loading: false,
}

export default {
	components: { KakaoSigninButton },
	setup() {
		const { route, router } = useRouter()
		const isCapsLockOn = ref(false)
		const kakaoRedirectUrl = ref(`${window.location.origin}/auth/kakao-signin`)
		const signinForm = ref({ ..._signinForm })

		const isSubmitValid = computed(() => {
			return (
				signinForm.value.id.length > 0 && signinForm.value.password.length > 0
			)
		})

		const formatIdPhone = id => {
			const phoneRegex = /^010\d{8}$/

			if (phoneRegex.test(id)) {
				id = internationalPhoneFormat(id)
			}

			return id
		}

		const signin = async () => {
			signinForm.value.loading = true
			const queryValue = route.value.query.redirectUri
			try {
				const data = { ...signinForm.value }

				data.id = formatIdPhone(data.id)
					.trim()
					.replace(/\t/g, '')
					.replace(/\r\n/g, '')

				await AuthService.signin(data)
				await AuthService.init()

				if (queryValue !== undefined) {
					router.push(queryValue)
				} else {
					router.push({ name: 'mypage-dashboard' })
				}
			} catch (error) {
				warningSwal({
					title: i18n.t('loginForm.submitMsg.fail.title'),
					html: i18n.t('loginForm.submitMsg.fail.html'),
					confirmButtonText: i18n.t(
						'loginForm.submitMsg.fail.confirmButtonText',
					),
					allowOutsideClick: true,
				})
			} finally {
				signinForm.value = _signinForm
				signinForm.value.loading = false
			}
		}

		const checkCapsLock = $event => {
			if ($event.getModifierState('CapsLock')) {
				isCapsLockOn.value = true
			} else {
				isCapsLockOn.value = false
			}
		}

		const routeToSignup = () => {
			router.push({ name: 'signup' })
		}

		const routeToFindPassword = () => {
			router.push({ name: 'find-password' })
		}

		const routeToFindEmail = () => {
			router.push({ name: 'find-email' })
		}

		store.dispatch('app/setIsLogoWhite', false)

		return {
			signinForm,
			isCapsLockOn,
			kakaoRedirectUrl,

			signin,
			checkCapsLock,
			routeToSignup,
			routeToFindPassword,
			routeToFindEmail,

			isSubmitValid,
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.v-main__wrap {
		background-color: white !important;
	}
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.Signin {
	padding-top: 36px;
	padding-left: 20px;
	padding-right: 20px;

	.v-card {
		padding-top: 60px;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 60px;

		width: 396px;
		max-width: 396px;
		border: 1px solid #e4e5ed;
		width: 100%;

		.form {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		@media screen and (max-width: 480px) {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		text-transform: none !important;
		letter-spacing: normal !important;
		font-size: 18px !important;
		font-weight: 900 !important;

		&.kakao-login {
			border: 0;
			background-color: #fee500 !important;
		}

		&.email-login {
			width: 400px;
		}
	}
}

.vertical-line {
	position: relative;
	top: 3px;
	height: 14px;
	width: 1px;
	margin: 0 10px;
	background-color: #dadada;
}
</style>
